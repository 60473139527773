import { Api } from "@/models/class/api.class";
import { AmortizationTransactionReportResDto } from "@/models/interface/amortization-transaction-report";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class AmortizationTransactionReportService extends HttpClient {
  getAmortizationTransactionReport(
    params?: RequestQueryParamsModel
  ): Promise<AmortizationTransactionReportResDto> {
    return this.get<AmortizationTransactionReportResDto>(
      Api.AmortizationTransactionReport,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReport(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.AmortizationTransactionReportDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const amortizationTransactionReportService =
  new AmortizationTransactionReportService();
